<template>
    <div class="container">
        <div class="restaurant">
            <v-row class="">
                <v-col cols="12" class="top-panel">
                    <div class="arrow-btn">
                        <router-link to="/Players">
                            <img src="../assets/img/arrowBack.svg" class="img-pluss-btn" alt="arrow-back" />
                        </router-link>
                    </div>
                    <div class="vertical-line-1"></div>
                </v-col>
            </v-row>
            <v-row class="">
                <v-col cols="12" class="title-container py-0">
                    <h1 class="page-title">{{ this.product.name }}</h1>
                    <!-- <div class="breadcrums-container">
              <router-link class="breadcrums-title" to="/Restaurants"
                ><span class="breadcrums-click">Рестораны / </span></router-link
              >
              <p class="breadcrums-title">CoffeonSaigon</p>
            </div> -->
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="title-container-tabs">
                    <template>
                        <div v-if="loading">
                            <v-tabs v-model="activeTab">
                                <v-tab v-for="(tab, index) in tabs" :key="index">
                                    {{ tab.title }}
                                </v-tab>

                                <v-tab-item v-for="(tab, index) in tabs" :key="index">
                                    <template v-slot:default>
                                        <component :is="tab.component" :responseData="product"></component>
                                    </template>
                                </v-tab-item>
                            </v-tabs>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<style scoped>
::v-deep.v-tabs .v-tabs-bar .v-tabs-slider {
    background-color: #BCB648 !important;
}

::v-deep .v-tabs-slider-wrapper {
    height: 4px !important;
}

.page-title {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 12px;
}

::v-deep .v-tab:before {
    background-color: gray;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

::v-deep .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
    color: var(--gray-1, #333);
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

::v-deep .v-tab {
    caret-color: #ff0000 !important;
    color: #000 !important;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 1 auto;
    font-size: 1.1rem;
    font-weight: 600;
    font-style: normal;
    justify-content: center;
    letter-spacing: normal;
    line-height: normal;
    min-width: 90px;
    max-width: 360px;
    outline: none;
    padding: 0 16px;
    position: relative;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: none;
    transition: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-family: "Inter";
}

.breadcrums-click {
    cursor: pointer;
}

.breadcrums-title {
    color: var(--02, #848484);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    text-decoration: none;
}

.breadcrums-container {
    display: flex;
    align-items: center;
    margin-top: 7px;
}

::v-deep .v-input__slot {
    border-style: none;
    margin-bottom: 0px;
}

.title-container {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 17px;
}

.title-container-tabs {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 0px;
}

.img-pluss-btn {
    margin-left: 10px;
}

.arrow-btn {
    /* border-right: 1px solid var(--04, #eaeaea); */
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* .top-panel .container {
      width: 100%;
      padding: 0px !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    } */
.vertical-line {
    width: 1px;
    height: 42px;
    background: #e0e0e0;
    margin-left: 31px;
}

.vertical-line-1 {
    width: 1px;
    height: 50px;
    background: #e0e0e0;
}

.add-btn .add-text {
    color: var(--01, #333);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 12px;
    transition: color 0.3s ease;
}

.add-btn:hover .add-text {
    /* Цвет текста при наведении */
    color: black;
    /* Замените "red" на желаемый цвет текста */
    font-weight: 500;
    font-size: 17px;
}

.add-btn {
    display: flex;
    align-items: center;
    padding: 12px 0px;
    margin-left: 37px;
    cursor: pointer;
}

.top-panel {
    border-bottom: 1px solid var(--04, #eaeaea);
    background: var(--white, #fff);
    height: 50px;
    display: flex;
    align-items: center;
}

@media (min-width: 1264px) {
    .container {
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {
    .container {
        max-width: 100% !important;
    }
}
</style>

<script>
import { mapGetters } from "vuex";
import playersBody from "../components/players/playersBody.vue";
import playersAchievement from "../components/players/playersAchievement.vue";
export default {
    name: "PlayerBody",
    methods: {
        getProduct() {
            this.$http
                .get(`/api/Player/${this.$route.params.playerId}`)
                .then((response) => {
                    this.product = response.data;
                    this.$store.commit("setRootRestaurant", this.product);
                    this.loading = true;
                })
                .catch((error) => {
                    alert(error);
                });
        },
    },
    computed: {
        ...mapGetters({
            user: "user",
        }),
    },
    mounted() {
        // this.initialize();
        //   this.getManufacturers();
        this.getProduct();
    },
    data() {
        return {
            activeTab: 0,
            tabs: [
                { title: "Об игроке", component: playersBody },

                { title: "Достижения", component: playersAchievement },
            ],
            product: {},
            loading: false,
        };
    },
};
</script>

<style lang="scss">
@media (min-width: 1264px) {
    .container {
        //1185px дефолтное название если что то ломаться начнется на маленьких мониторах
        max-width: 100% !important;
    }
}

@media (min-width: 960px) {

    //900 дефолтное название если что то ломаться начнется на маленьких мониторах
    .container {
        max-width: 100% !important;
    }
}
</style>